//Dependencias
import thunk from "redux-thunk";
import rootReducer from "../Reducers";
import { createStore, compose, applyMiddleware } from "redux";
/**
 * @author key 20.08.21
 * la validación comentada se rompió en un merge :( asi que lo dejé en la versión luego lo revisaré */
// export const composeEnhances =
//   process.env.NODE_ENV == "development"
//     ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//     : null || compose;
export const composeEnhances =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const Store = createStore(
  rootReducer,
  composeEnhances(applyMiddleware(thunk)),
);

export default Store;
