import { useEffect, useState } from "react";
import request from "../../../../../requests/request";
import { dataComprasNCs } from "../../../../../requests/urls";
import {
  getCalculoTotal,
  getCalculoRestaDescuento,
  getFechasCalculo,
  getPorcentajesCompras,
  getMontosDeIVA,
  calculoMontoResta,
} from "./utils";

const useCalculoComprasNC = (idProveedor, selection, dataGral) => {
  const [dataComprobantes, setDataComprobantes] = useState(null);
  const [loadingComprobantes, setLoadingComprobantes] = useState(false);
  const [newCompras, setNewCompras] = useState([]);

  useEffect(() => {
    getDataComprobantes();
  }, [dataGral.fechaReal]);

  useEffect(() => {
    getMontosConDescuento();
  }, [dataGral.descuento]);

  const getDataComprobantes = async () => {
    setLoadingComprobantes(true);
    const dataIds = getDataIds();
    const fechas = getFechasCalculo(dataGral.fechaReal);

    try {
      const response = await request({
        method: "GET",
        url: dataComprasNCs(dataIds),
        params: {
          desde: fechas.desde,
          hasta: fechas.hasta,
          id_proveedor: Number(idProveedor),
        },
      });
      if (response.status === 201) {
        setDataComprobantes(response.data);
        calculoNewMontos(response.data);
      }
      if (response.status === 204) {
        setDataComprobantes([]);
        setNewCompras([]);
        setLoadingComprobantes(false);
      }
    } catch (err) {
      console.log(err);
      setLoadingComprobantes(false);
    }
  };

  const getDataIds = () => {
    let compras = [];
    let notasCredito = [];

    selection.map((s) => {
      if (s.tipo_comprobante.nombre === "Notas de Crédito") {
        notasCredito.push(s.id);
      } else {
        compras.push(s.id);
      }
    });

    const comprasQuery =
      compras.length > 0
        ? compras.map((id) => `ids_compras=${id}`).join("&")
        : "";
    const ncQuery =
      notasCredito.length > 0
        ? notasCredito.map((id) => `ids_nc=${id}`).join("&")
        : "";
    return comprasQuery + "&" + ncQuery;
  };

  const calculoNewMontos = (data) => {
    const { compras, notas_credito } = data.montos_netos_compras;
    let sumaNeto = 0;
    let sumaIVA21 = 0;
    let sumaIVA105 = 0;
    let sumaPercepciones = 0;

    compras.forEach((compra) => {
      const { montoIVA21, montoIVA105 } = getMontosDeIVA(compra);
      sumaNeto = sumaNeto + Number(compra.monto_neto);
      sumaIVA21 = sumaIVA21 + Number(montoIVA21);
      sumaIVA105 = sumaIVA105 + Number(montoIVA105);
      sumaPercepciones = sumaPercepciones + Number(compra.percepciones);
    });

    let newDataCompras = getPorcentajesCompras(
      compras,
      sumaNeto,
      sumaIVA21,
      sumaIVA105,
      sumaPercepciones,
    );
    const totalesNC = getTotalesNC(notas_credito);

    newDataCompras.forEach((item) => {
      const montosARestarNC = {};
      const newMontos = {};
      const { montoIVA21, montoIVA105 } = getMontosDeIVA(item);
      let montoSumarANeto = 0; //Aca se sumaran los montos de la nc que no se asignen a ninguna compra

      //Obtenemos el monto a restar del IVA 21%
      const resultIVA21 = calculoMontoResta(
        item.porcentajes.porcIVA21,
        Number(totalesNC.totalIVA21),
        sumaIVA21,
      );
      montosARestarNC.IVA21 = resultIVA21.resta;
      newMontos.IVA21 = getCalculoTotal(
        Number(montoIVA21),
        resultIVA21.resta,
        0,
      );
      montoSumarANeto = montoSumarANeto + resultIVA21.acumular;

      //Obtenemos el monto a restar del IVA 10.5%
      const resultIVA105 = calculoMontoResta(
        item.porcentajes.porcIVA105,
        Number(totalesNC.totalIVA105),
        sumaIVA105,
      );
      montosARestarNC.IVA105 = resultIVA105.resta;
      newMontos.IVA105 = getCalculoTotal(
        Number(montoIVA105),
        resultIVA105.resta,
        0,
      );
      montoSumarANeto = montoSumarANeto + resultIVA105.acumular;

      //Obtenemos el monto a restar de las percepciones
      const resultPercepciones = calculoMontoResta(
        item.porcentajes.porcPercepcion,
        Number(totalesNC.totalPercepciones),
        sumaPercepciones,
      );
      montosARestarNC.percepciones = resultPercepciones.resta;
      newMontos.percepciones = getCalculoTotal(
        Number(item.percepciones),
        resultPercepciones.resta,
        0,
      );
      montoSumarANeto = montoSumarANeto + resultPercepciones.acumular;

      //Obtenemos el monto a restar del neto
      let montoTotalNeto =
        Number(totalesNC.totalNeto) + Number(montoSumarANeto);
      const restaNeto = Number(
        Number((Number(montoTotalNeto) * item.porcentajes.porcNeto) / 100),
      );
      montosARestarNC.neto = restaNeto;
      newMontos.neto = getCalculoTotal(Number(item.monto_neto), restaNeto, 0);

      //Obtenemos el monto a restar del total
      const restaTotal = Number(
        Number(
          restaNeto +
            resultIVA21.resta +
            resultIVA105.resta +
            resultPercepciones.resta,
        ),
      );
      montosARestarNC.total = restaTotal;
      newMontos.total = getCalculoTotal(
        Number(item.deuda_restante),
        restaTotal,
        0,
      );

      //Seteamos informacion adicional respecto de las NC
      item.infoAdicionalNC = {
        montoSumarANeto: Number(montoSumarANeto),
      };

      //Seteamos el monto inicial que sera el monto a pagar menos las restas de la nc
      item.monto_inicial = getCalculoTotal(
        Number(item.deuda_restante),
        restaTotal,
        0,
      );
      item.montosARestarNC = montosARestarNC;
      item.newMontos = newMontos;

      //Buscamos dentro de data.compras el numero comprobante de esta compra
      const nroComprob = data.compras.filter((c) => c.id === item.compra)[0];

      item.nroComprob = nroComprob ? nroComprob.formatted_number : "";
      item.montoAPagar = getCalculoTotal(
        Number(item.deuda_restante),
        restaTotal,
        0,
      );
      item.errorMontoPagar = false;
      item.msgErrorMontoPagar = false;
      item.porcPago = 100;
    });

    setLoadingComprobantes(false);
    setNewCompras(newDataCompras);
  };

  const getTotalesNC = (notas_credito) => {
    let totalNeto = 0;
    let total = 0;
    let totalPercepciones = 0;
    let totalIVA21 = 0;
    let totalIVA105 = 0;

    notas_credito.forEach((nc) => {
      //Obtenemos el monto para cada tipo de IVA
      const { montoIVA21, montoIVA105 } = getMontosDeIVA(nc);

      totalNeto = totalNeto + Number(nc.monto_neto);
      totalIVA21 = Number((totalIVA21 + montoIVA21).toFixed(2));
      totalIVA105 = totalIVA105 + montoIVA105;
      totalPercepciones = totalPercepciones + Number(nc.percepciones);
      total = total + Number(nc.monto_total);
    });

    return { totalNeto, totalIVA21, totalIVA105, totalPercepciones, total };
  };

  const getMontosConDescuento = () => {
    let copyNewCompras = newCompras.slice();
    const porc = Number((Number(dataGral.descuento) / 100).toFixed(7));

    copyNewCompras.forEach((c) => {
      const montosARestarDescuento = {};
      const { montoIVA21, montoIVA105 } = getMontosDeIVA(c);
      //Obtenemos el monto a restar del neto
      let restaDescNeto = getCalculoRestaDescuento(
        Number(c.monto_neto),
        c.montosARestarNC.neto,
        porc,
      );
      montosARestarDescuento.neto = restaDescNeto;
      c.newMontos.neto = getCalculoTotal(
        Number(c.monto_neto),
        c.montosARestarNC.neto,
        restaDescNeto,
      );
      //Obtenemos el monto a restar de las percepciones
      let restaDescPercepciones = getCalculoRestaDescuento(
        Number(c.percepciones),
        c.montosARestarNC.percepciones,
        porc,
      );
      montosARestarDescuento.percepciones = restaDescPercepciones;
      c.newMontos.percepciones = getCalculoTotal(
        Number(c.percepciones),
        c.montosARestarNC.percepciones,
        restaDescPercepciones,
      );
      //Obtenemos el monto a restar del IVA 21%
      let restaDescIVA21 = getCalculoRestaDescuento(
        Number(montoIVA21),
        c.montosARestarNC.IVA21,
        porc,
      );
      montosARestarDescuento.IVA21 = restaDescIVA21;
      c.newMontos.IVA21 = getCalculoTotal(
        Number(montoIVA21),
        c.montosARestarNC.IVA21,
        restaDescIVA21,
      );
      //Obtenemos el monto a restar del IVA 10.5%
      let restaDescIVA105 = getCalculoRestaDescuento(
        Number(montoIVA105),
        c.montosARestarNC.IVA105,
        porc,
      );
      montosARestarDescuento.IVA105 = restaDescIVA105;
      c.newMontos.IVA105 = getCalculoTotal(
        Number(montoIVA105),
        c.montosARestarNC.IVA105,
        restaDescIVA105,
      );
      //Obtenemos el monto a restar del total
      let restaDescTotal = getCalculoRestaDescuento(
        Number(c.deuda_restante),
        c.montosARestarNC.total,
        porc,
      );
      montosARestarDescuento.total = restaDescTotal;
      c.newMontos.total = getCalculoTotal(
        Number(c.deuda_restante),
        c.montosARestarNC.total,
        restaDescTotal,
      );
      c.montosARestarDescuento = montosARestarDescuento;
      c.montoAPagar = getCalculoTotal(
        Number(c.deuda_restante),
        c.montosARestarNC.total,
        restaDescTotal,
      );
    });
    setNewCompras(copyNewCompras);
  };

  return {
    useComprasNC: {
      newCompras,
      loadingComprobantes,
      setNewCompras,
      dataComprobantes,
    },
  };
};

export default useCalculoComprasNC;
