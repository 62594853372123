import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "shards-react";
import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import MainSidebar from "../components/layout/MainSidebar/MainSidebar";
import { ModulosContextProvider } from "../context/ModulosContext";
import validarToken from "../hooks/validarToken";

export default function DefaultLayout({ children, noNavbar, location }) {
  const [modalVerificacionToken, setModalVerificacionToken] = useState(false);

  const { configGeneral } = useSelector((state) => state.configGeneral);

  // Valida el token al cargar
  useEffect(() => {
    validarToken((state) => {
      setModalVerificacionToken(state);
    });
  }, []);

  const aceptarValidacion = () => {
    setModalVerificacionToken(false);
    localStorage.clear();
  };

  return (
    <ModulosContextProvider>
      <div>
        <Modal
          className="modalValidarToken"
          open={modalVerificacionToken}
          toggle={() => setModalVerificacionToken(!modalVerificacionToken)}
        >
          <ModalHeader>
            <h2>¡Atención!</h2>
          </ModalHeader>
          <ModalBody>
            <p className="text-center">
              Su cuenta fue iniciada en otro dispositivo.
            </p>
            <p className="text-center">
              Para continuar, presione Aceptar para volver a iniciar sesión.
            </p>
          </ModalBody>
          <ModalFooter>
            <Button theme="success" onClick={aceptarValidacion}>
              Aceptar
            </Button>
          </ModalFooter>
        </Modal>

        <Container
          fluid
          className={
            configGeneral.sidebar_menu_config
              ? "icon-sidebar-nav"
              : "icon-sidebar-nav-fixed"
          }
        >
          <Row>
            <MainSidebar />
            <Col className="main-content col" tag="main">
              {!noNavbar && <MainNavbar location={location} />}
              {children}
            </Col>
          </Row>
        </Container>
      </div>
    </ModulosContextProvider>
  );
}

DefaultLayout.propTypes = {
  noNavbar: PropTypes.bool,
};

DefaultLayout.defaultProps = {
  noNavbar: false,
};
